import * as yup from "yup";

const schemaValidation = yup
  .object({
    email: yup.string().required(),
    name: yup.string().required(),
    description: yup.string(),
    disDescription: yup.string(),
    identifierDescription: yup.string(),
    identifierUrl: yup.string(),
    creativeName: yup.string(),
    creativeDescription: yup.string(),
    creativeURL: yup.string(),
    eventName: yup.string(),
    eventDescription: yup.string(),
    eventURL: yup.string(),
    eventStartDate: yup.string(),
    eventEndDate: yup.string(),
    eventLocation: yup.string(),
    productName: yup.string(),
    productDescription: yup.string(),
    productURL: yup.string(),
    productImageExternalURL: yup.string(),
    productImageName: yup.string(),
    productImageDescription: yup.string(),
    serviceName: yup.string(),
    serviceDescription: yup.string(),
    serviceURL: yup.string(),
    serviceProvider: yup.string(),
    serviceType: yup.string(),
    imageExternalURL: yup.string(),
    imageName: yup.string(),
    imageDescription: yup.string(),
    mainUrl: yup.string(),
    adminName: yup.string(),
    adminDescription: yup.string(),
    adminAddress: yup.string(),
    areaServedPlace: yup.string(),
    areaServedOther: yup.string(),
    eligiblePlace: yup.string(),
    eligibleOther: yup.string(),
    ineligiblePlace: yup.string(),
    ineligibleOther: yup.string(),
    validFrom: yup.string(),
    validThrough: yup.string(),
    minPrice: yup.number(),
    maxPrice: yup.number(),
    price: yup.number(),
    priceCurrency: yup.string(),
    availabilityStarts: yup.string(),
    availabilityEnds: yup.string(),
    serialNumber: yup.string(),
    gtin: yup.string(),
    gtinURL: yup.string(),
    mpn: yup.string(),
    givenName: yup.string(),
    familyName: yup.string(),
    personEmail: yup.string(),
    affiliation: yup.string(),
    organName: yup.string(),
    organEmail: yup.string(),
    organAddress: yup.string(),
    durationValue: yup.string(),
    unitCode: yup.string(),
    unitText: yup.string(),
  })
  .required();

export default schemaValidation;
